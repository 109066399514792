
// form validation
.form-validation{
    .ng-pristine {} // When it is first initialized
    .ng-dirty {} // When the view changes
    .ng-valid.ng-dirty {
        border-color: $brand-success;
    }
    .ng-invalid.ng-dirty {
        border-color: $brand-danger;
    }
}
@media (min-width: $screen-sm) {
    .app.nav-collapsed-min {

        // default
        .slimScrollDiv {
            overflow: visible !important; // overrides slimScroll plugin
            height: auto !important;

            .slimScrollBar,
            .slimScrollRail {
                display: none !important;
            }
        }

        #nav-container {
            width: 60px;
        }
        .content-container {
            margin-left: 60px;
        }

        #nav-wrapper {
            overflow: visible !important;
        }
        #nav {
            overflow: visible !important;
            li {
                &:hover,
                &.open {
                    > ul {
                        display: block !important;
                    }
                }
                ul {
                    z-index: 9;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    width: 220px;
                    @include border-right-radius($border-radius-base);
                    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
                }
            }

            // first level menu
            > li {
                > a {
                    padding: 15px 15px;
                    text-align: center;

                    > i {
                        margin: 0;
                        font-size: 20px;
                    }
                    > span {
                        display: none;
                    }
                }

                // only hide for first level
                > .icon-has-ul {
                    display: none;
                }
            }
        }

        .nav-title {
            display: none;
        }

    }
}
.body-auth {

    #content {
      background: none;
      background-color: #eeeeee;
    }

    .form-response {
      margin-left: 2%;
      margin-right: 2%;
    }

    @media only screen and (min-width: 600px)
    {
        #content
        {
            background-image: url("#{$image_path}cal_rev.jpg");
            background-size: cover;
            margin-bottom: 0px;
        }

        .login-responsive {
          background: #ffffff;
          border: 1px solid white;
          box-shadow: 0px 0px 1px 1px #1c7ebb;
        }

        .form-response {
          margin-left: 0;
          margin-right: 0;
        }

    }


    // #content {
    //     /*background-color: #eeeeee;*/
    //     background-image: url("#{$image_path}rever_backgr_4.jpg");
    //     background-size: cover;
    //     margin-bottom: 0px;
    //
    // }
}

.container{
    position: fixed;
    height: 400px;
    width: 500px;
    top: 50%;
    left: 50%;

        .form-container{
            position: relative;
            right: 250px;
            bottom: 200px;
        }
}


.page-signin,
//.page-change,
.page-signup,
.page-forgot {

    //
    .signin-header {
        padding: 25px 0;
        background-color: #dfe0e6;
    }

    .logo {
        font-size: 46px;
        font-weight: bold;
        a {
            color: $text-color;
            &:hover {
                text-decoration: none;
            }
        }
    }

    //
    .main-body {
        padding: 20px 10px;
    }

    .info {
        padding: 25px 0 0;
        max-width: 420px;
        margin: 0 auto 20px;

        h2 {
            font-size: 18px;
            color: $dark;
        }
    }

    .form-container {
        max-width: 420px;
        margin: 10px auto;


    }

    // Line through
    .line-thru {
        margin: 1em 0;
        display: block;
        font-size: .875em;
        font-weight: bold;
        margin-bottom: 1em;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 1;
        &:before {
            width: 50px;
            height: 10px;
            background-color: $body-bg;
            content: '';
            margin: -5px 0 0 -20px;
            left: 50%;
            position: absolute;
            top: 50%;
            z-index: -1;
        }
        &:after {
            width: 100%;
            border-bottom: 1px solid darken($body-bg, 10%);
            content: '';
            display: block;
            position: absolute;
            top: 49%;
            z-index: -2;
        }
    }
}

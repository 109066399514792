.body-err {
    #content {
        background-color: $body-bg;
        height: 100%;

        > .view-container {
            height: 100%;
        }
    }

    &.body-wide {
        #content {
            padding-bottom: 0;
        }
    }
}

.page-err {
    width: 100%;
    height: 100%;
    background-color: lighten($brand-primary, 5%);

    .err-status {
        background-color: $body-bg;
        h1 {
            margin: 100px 0 -45px;
            color: $brand-primary;
            font-size: 200px;            
        }
    }
    .err-message {
        background-color: $brand-primary;
        padding: 24px;
        text-transform: uppercase;
        h2 {
            font-size: 50px;
            color: $reverse;
            font-weight: 300;
        }
    }

    .err-body {
        padding: 100px 10px 20px;
    }

    .btn-goback {
        color: $reverse;
        background-color: transparent; 
        border-color: $reverse;

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: lighten($brand-primary, 5%);
            background-color: $reverse;
        }
        .open & {
            &.dropdown-toggle {
                color: lighten($brand-primary, 5%);
                background-color: $reverse;
            }
        }
    }

}
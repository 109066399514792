.flags-sprite,
.flags-american,
.flags-american-min,
.flags-china,
.flags-china-min,
.flags-france,
.flags-germany,
.flags-italy,
.flags-italy-min,
.flags-japan,
.flags-japan-min,
.flags-korea,
.flags-korea-min,
.flags-portugal,
.flags-portugal-min,
.flags-russia,
.flags-russia-min,
.flags-spain-min,
.flags-spain{background-image:url('ui/images/flags.png');background-repeat:no-repeat}
.flags-american{background-position:0 0;height:32px;width:32px}
.flags-china{background-position:0 -32px;height:32px;width:32px}
.flags-france{background-position:0 -64px;height:32px;width:32px}
.flags-germany{background-position:0 -96px;height:32px;width:32px}
.flags-italy{background-position:0 -128px;height:32px;width:32px}
.flags-japan{background-position:0 -160px;height:32px;width:32px}
.flags-korea{background-position:0 -192px;height:32px;width:32px}
.flags-portugal{background-position:0 -224px;height:32px;width:32px}
.flags-russia{background-position:0 -256px;height:32px;width:32px}
.flags-spain{background-position:0 -288px;height:32px;width:32px}
.flags-american-min{background-size: 16px; background-position: 0 0px;height:16px;width:32px}
.flags-china-min{background-size: 16px; background-position: 0 -16px;height:16px;width:32px}
.flags-italy-min{background-size: 16px; background-position: 0 -64px;height:16px;width:32px}
.flags-japan-min{background-size: 16px; background-position: 0 -80px;height:16px;width:32px}
.flags-korea-min{background-size: 16px; background-position: 0 -96px;height:16px;width:32px}
.flags-portugal-min{background-size: 16px; background-position: 0 -112px;height:16px;width:32px}
.flags-russia-min{background-size: 16px; background-position: 0 -128px;height:16px;width:32px}
.flags-spain-min{background-size: 16px; background-position: 0 -144px;height:16px;width:32px}

.footer{
  font-size: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px;

}

@media screen and (min-width: 600px) {

  .main-container{

  }

  .footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
  }
}

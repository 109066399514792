// .popover {}

.popover-title {
    padding: 8px 14px 0;
    color: #aaa;
    font-weight: bold;
    border-bottom: none;
}

.popover-content {
    color: $bright;
}
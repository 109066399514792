.breadcrumb {
    a {
        color: $text-color;
        &:hover {
            text-decoration: none;
        }
    }
}

.breadcrumb-alt {
    overflow: hidden; // clearfix
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    width: 100%;

    > li {
        float: left;
        margin: 0 25px 0 0;

        > a {
            position: relative;
            float: left;
            background-color: #5b5f63;
            color: #ffffff;
            font-size: 12px;
            padding: 10px;
            &:before {
                position: absolute;
                top: 50%;
                left: -1em;
                margin-top: -1.6em;
                border-color: #5b5f63 #5b5f63 #5b5f63 rgba(0,0,0,0);
                border-style: solid;
                border-width: 1.5em 0 1.7em 1em;
                content: "";
            }
            &:after {
                position: absolute;
                top: 50%;
                right: -1em;
                margin-top: -1.5em;
                border-bottom: 1.5em solid rgba(0,0,0,0);
                border-left: 1em solid #5b5f63;
                border-top: 1.5em solid rgba(0,0,0,0);
                content: "";
            }
            &:hover,
            &.active {
                text-decoration: none;
                background-color: $brand-primary;
                color: #fff;
                &:before{
                    border-color: $brand-primary $brand-primary $brand-primary rgba(0,0,0,0);
                }
                &:after {
                    border-left: 1em solid $brand-primary;
                }
            }
        }
    }
}

.breadcrumb-alt.breadcrumb-home{
    padding: 0 15px 15px 26px;
    margin: 0;
}



// weather icons
.weather-icon-list {
    text-align: center;
    .example {
        text-align: center;
        margin: 10px 3px;

        .icon {
            font-size: 24px;
        }
    }
}

// glyphicons icons
.bs-glyphicons {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;

    .glyphicon {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 24px;
    }

    .glyphicon-class {
        display: block;
        text-align: center;
    }

    li {
        @media (min-width: 768px) {
            width: 12.5%;
        }

        float: left;
        width: 25%;
        height: 115px;
        padding: 10px;
        margin: 0 -1px -1px 0;
        font-size: 12px;
        line-height: 1.4;
        text-align: center;
        border: 1px solid #ddd;
    }
}

.page-grid .grid-structure .row {
    margin-top: 10px;

    .widget-container {
        margin-top: 5px;
        background: #eee;
        padding: 10px 15px 12px;
        min-height: 0;
        border-radius: $border-radius-base;
    }

}

// form 
.page-form-ele {
    .list-checkbox-radio li {
        &:nth-child(even) {
            margin-bottom: 20px;
        }

        & > div {
            float: left;
            margin-right: 10px;
        }
    }
}

// demo slider 
.demoslider-container {
    max-width: 600px;
    margin: auto;
}
.page-dashboard {
    .mini-box {
        padding: 30px 25px;
    }

    .panel-box {
        .panel-top .divider-xl {
            margin: 85px 0;
        }

        .panel-bottom {
            padding: 17px 10px;
        }
    }
}

#nav-container {
    ul {
        padding-left: 0;
        list-style: none;
    }

    > .nav-wrapper {
        position: relative;
        width: 100%;
        height: 250px;
        margin-bottom: 10px;
        border: 2px;
    }

    > .nav-wrapper-plant {
        bottom: 0px;
    }
}
// Layout
.nav-container {
    .nav {
        > li {}
        // all sub ul
        ul {
            display: none;
        }
    }
}
// Style
.nav-container {
    .icon-has-ul {
        position: absolute;
        right: 15px;
        font-size: 10px;
        color: $text-color;
    }
    //
    .nav {
        margin: 0;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        -webkit-overflow-scrolling: -blackberry-touch;

        .nav-title {
            margin: 15px 15px 10px;
            font-size: $font-size-small;
        }

        .divider {
            margin: 10px 0;
            border-width: 1px;
            border-style: solid;
        }

         > li > a > .nav-icon,
        > li > a > i {
            display: inline-block;
            margin-right: 10px;
            width: 20px;
            line-height: 1;
            text-align: center;
            font-size: 14px;
            transition: all $nav-transition_duration ease-in-out;
        }

        a {
            font-size: 13px;
            transition: all $nav-transition_duration ease-in-out;

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

        > li {
            position: relative;
            margin: 0;
            text-align: left;
            font-weight: bold;
            transition: all $nav-transition_duration ease-in-out;

            &:first-child > a {
                border-top: 0;
            }

            > a {
                position: relative;
                display: block;
                padding: 15px;
                transition: all $nav-transition_duration ease-in-out;

                .badge {
                    position: absolute;
                    top: 16px;
                    right: 8px;
                }
            }

            > .icon-has-ul {
                top: 15px;
                line-height: 19px;
            }
        }
        // sub menu
        ul {
            a {
                position: relative;
                font-size: 13px;
            }

            .icon-has-ul {
                top: 13px;
                line-height: 18px;
            }

            li {
                position: relative;
                padding: 0;

                &:last-child > a {
                    border-bottom: 0;
                }

                > a {
                    position: relative;
                    display: block;
                    padding: 13px 0 13px 25px;
                    transition: all $nav-transition_duration ease-in-out;

                    &:first-child {
                        border-top: none;
                    }

                    > i {
                        font-size: 9px;
                        margin-right: 16px;
                        line-height: 14px;
                        vertical-align: middle;

                        &.fa-circle,
                        &.fa-circle-o {
                            font-size: 5px;
                        }

                        &.fa-caret-right {
                            font-size: 9px;
                        }
                    }

                    > .badge {
                        position: absolute;
                        right: 8px;
                        top: 12px;
                    }
                }
            }
        }
    }
}

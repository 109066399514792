.nav-container {
    &:before {
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        width: 2px;
        z-index: 2;
    }

    &.bg-white {
        &:before {
            box-shadow: inset -1px 0 1px rgba(255, 255, 255, 0.3);
        }
        .nav {
            a {
                color: $text-color;
            }

            .nav-title {
                color: $text-color;
            }

            .divider {
                border-color: $gray-lighter;
            }

            > li {
                &:hover {
                    > a {
                        background-color: $light_bg_link_hover_bg;
                        color: $light_bg_link_hover_color;
                    }
                    > .icon-has-ul,
                    > .icon-has-ul-h { color: $light_bg_link_hover_color; }
                }
                &.active {
                    > a {
                        // background-color: $light_bg_link_active_bg;
                        color: $light_bg_link_active_color;
                    }                        
                    > .icon-has-ul { color: $light_bg_link_active_color; }
                }

                // overrides Bootstrap 
                > a:focus {
                    background-color: $light_bg_link_hover_bg;
                }
            }

            // sub menu
            ul {
                background-color: $light_bg_sub_nav_bg;

                > li {
                    &:hover,
                    &.active {
                        > a {
                            background-color: $light_bg_sub2_nav_bg;
                            // color: $brand-primary;
                        }
                    }
                }
            }

            // sub2 menu
            ul ul {
                background-color: $light_bg_sub2_nav_bg;
                > li {
                    &:hover,
                    &.active {
                        > a {
                            background-color: $light_bg_sub3_nav_bg;
                            // color: $brand-primary;
                        }
                    }
                } 
            }
        }

        // Vertical NAV only
        &.nav-vertical {
            .nav {
                > li {
                    // border-bottom: 1px solid rgba(0,0,0,.06);
                    &.open {
                        > a {
                            background-color: $light_bg_link_open_bg;
                            color: $light_bg_link_open_color;
                            &:hover,
                            &:focus,
                            &:active {
                                background-color: $light_bg_link_open_bg;
                                color: $light_bg_link_open_color;
                            }
                        }
                        > .icon-has-ul,
                        > .icon-has-ul-h { color: $light_bg_link_open_color; }               
                    }
                }
            }
        }        
    }

    &.bg-dark {
        background-color: $dark;
        &:before {
            box-shadow: inset -1px 0 1px rgba(0, 0, 0, 0.3);
        }
        .nav {

            a {
                color: #ccc;
                color: rgba(255,255,255,.5);
            }

            .nav-title {
                color: #666;
            }

            .divider {
                border-color: darken($dark, 5%);
            }


            > li {
                &:hover {
                    > a {
                        background-color: $dark_bg_link_hover_bg;
                        color: $dark_bg_link_hover_color;
                    }
                    > .icon-has-ul,
                    > .icon-has-ul-h { color: $dark_bg_link_hover_color; }
                }
                &.active {
                    > a {
                        // background-color: $dark_bg_link_active_bg;
                        color: $dark_bg_link_active_color;
                    }                        
                    > .icon-has-ul { color: $dark_bg_link_active_color; }
                }
                // overrides Bootstrap 
                > a:focus {
                    background-color: $dark_bg_link_hover_bg;
                }
            }

            // sub menu
            ul {
                background-color: $dark_bg_sub_nav_bg;

                > li {
                    &.open,
                    &:hover,
                    &.active {
                        > a {
                            background-color: $dark_bg_sub2_nav_bg;
                            color: $dark_bg_link_active_color;
                        }
                    }
                }
            }

            // sub2 menu
            ul ul {
                background-color: $dark_bg_sub2_nav_bg;
                > li {
                    &.open,
                    &:hover,
                    &.active {
                        > a {
                            background-color: $dark_bg_sub3_nav_bg;
                            color: $dark_bg_link_active_color;
                        }
                    }
                } 
            }
        }

        // Vertical NAV only
        &.nav-vertical {
            .nav {
                > li {
                    // border-bottom: 1px solid rgba(255,255,255, 0.05);
                    &.open {
                        > a {
                            background-color: $dark_bg_link_open_bg;
                            color: $dark_bg_link_open_color;
                            &:hover,
                            &:focus,
                            &:active {
                                background-color: $dark_bg_link_open_bg;
                                color: $dark_bg_link_open_color;                                
                            }
                        }
                        > .icon-has-ul,
                        > .icon-has-ul-h { color: $dark_bg_link_open_color; }               
                    }
                }
            }
        }  

    }

}


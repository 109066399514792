// .badge {
//     font-weight: 300;
// }

.badge-primary {
    background-color: $brand-primary;
}

.badge-success {
    background-color: $brand-success;   
}

.badge-info {
    background-color: $brand-info;   
}

.badge-warning {
    background-color: $brand-warning;   
}

.badge-danger {
    background-color: $brand-danger;   
}
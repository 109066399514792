.callout {
    margin: 20px 0;
    padding: 20px;
    border-left: 3px solid #eee;

    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.callout-success {
    background-color: #f3faf3;
    border-color: $brand-success;

    h4 {
        color: $brand-success;
    }   
}

.callout-info {
    background-color: #f4f8fa;
    border-color: $brand-info;

    h4 {
        color: $brand-info;
    }
}

.callout-warning {
    background-color: #fcf8f2;
    border-color: $brand-warning;

    h4 {
        color: $brand-warning;
    }
}

.callout-danger {
    background-color: #fdf7f7;
    border-color: $brand-danger;

    h4 {
        color: $brand-danger;
    }
}




@mixin bg-style ($background, $color) {
    background-color: $background;
    color: $color;
    a {
        color: $color;
        &:hover {
            color: $color;
        }
    }
}

@mixin bg-variant-alt ($color) {
    background-color: $color;
    color: $reverse;
    &:hover,
    &:focus {
        background-color: $color;
        color: $reverse;
    }
}

body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

::selection {
    background: $brand-primary;
    color: $reverse;
}
::-moz-selection {
    background: $brand-primary;
    color: $reverse;
}

a {
    text-decoration: none;
    &:active,
    &:focus{
        text-decoration: none;
        outline: none !important;
    }
}

span.block {
    display: block;
}

.no-margin {
    margin: 0;
}
.no-border {
    border: 0;
}

// background
// -----------------
// overrides Bootstrap
a.bg-primary { @include bg-variant-alt ($brand-primary); }
a.bg-success { @include bg-variant-alt ($brand-success); }
a.bg-info { @include bg-variant-alt ($brand-info); }
a.bg-warning { @include bg-variant-alt ($brand-warning); }
a.bg-danger { @include bg-variant-alt ($brand-danger); }
a.bg-orange { @include bg-variant-alt ($color-orange); }

// normal
.bg-white { @include bg-style(#fff, $text-color); }
.bg-body { @include bg-style($body-bg, $text-color); }
.bg-dark { @include bg-style($dark, $reverse); }
.bg-dark-light { @include bg-style($dark-light, $reverse); }
.bg-black { @include bg-style(#000, $reverse); }
.bg-primary { @include bg-style($brand-primary, $reverse); }
.bg-primary-light { @include bg-style($skin-light, $reverse); }
.bg-success { @include bg-style($brand-success, $reverse); }
.bg-reverberi { @include bg-style(#1C7EBB, $reverse); }
.bg-info { @include bg-style($brand-info, $reverse); }
.bg-info-alt { @include bg-style($brand-info-alt, $reverse); }
.bg-warning { @include bg-style($brand-warning, $reverse); }

.bg-danger { @include bg-style($brand-danger, $reverse); }
.bg-orange { @include bg-style($color-orange, $reverse); }
.bg-violet { @include bg-style($color-violet, $reverse); }
.bg-dark { @include bg-style($dark, $reverse);background-color:#5b5f63; }
.bg-bright { @include bg-style($bright, $text-color); }
.bg-reverse { @include bg-style($reverse, $text-color); }

.bg-facebook { @include bg-style(#335397, $reverse); }
.bg-twitter { @include bg-style(#00c7f7, $reverse); }
.bg-google-plus { @include bg-style(#df4a32, $reverse); }


.bg-primary-widget { @include bg-style(#1772bb, $reverse); }
.bg-success-widget { @include bg-style(#1b4671, $reverse); }
.bg-danger-widget { @include bg-style(#4694c3, $reverse); }
.bg-warning-widget { @include bg-style(#275f96, $reverse); }
.bg-primary-second-widget { @include bg-style(#5bb7ec, $reverse); }
